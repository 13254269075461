import BarChart from "../../graphs/BarChart";
import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import excelFile from "../../assets/excel/EXP01 Pulse Report Export.xlsx";
interface President {
  Country: string;
  LEVersion: string;
  BU: number;
  Account: string;
  LY: number;
  GAC: string; // Treating GAC as a string to handle mixed content

  BUAmendedQ224: number;
  ACLEGACQ224: string;
  LYAmendedQ224: number;
}

interface Condition {
  Account: string;
  Country: string;
  LEVersion: string;
}

interface Result {
  Account: string;
  Country: string;
  LEVersion: string;
  gacValue: number;
  lyValue: number;
  percent: number;
}

const PerformanceCharts = () => {
  const [pres, setPres] = useState<President[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [percent, setPercent] = useState<any | null>(null);
  const [totalABI, setTotalABI] = useState<any | null>(null);
  const [fiveCountriesPercent, setFiveCountriesPercent] = useState<
    string | null
  >(null);
  const [countryPercentArray, setCountryPercentArray] = useState<
    { country: string; value: any; 2023: any; 2024: any }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(excelFile);

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Ensure the file is read as binary
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Generate array of presidents from the first worksheet
        const data: any[] = utils.sheet_to_json<any>(worksheet);

        const renamedData = data.map((row) => ({
          ...row,
          Account: row["Accounts IM Simplified"], // Rename column
          Country: row["GAC Reporting Country"], // Rename column
          LEVersion: row["SS: CY Versions"], // Rename column
          ACLEGACQ424: row["AC/LEQ4"],

          // Add any other necessary renames here
        }));

        setPres(renamedData);

        // Define the original conditions to fetch GAC and LY values
        const conditions: any[] = [
          { Account: "Volume", Country: "US Beer", LEVersion: "LE 12+0 2024" },
          { Account: "Volume", Country: "Mexico", LEVersion: "LE 12+0 2024" },
          {
            Account: "Volume",
            Country: "Brazil Beer",
            LEVersion: "LE 12+0 2024",
          },
          { Account: "Volume", Country: "China", LEVersion: "LE 12+0 2024" },
          {
            Account: "Volume",
            Country: "Colombia Beer",
            LEVersion: "LE 12+0 2024",
          },
          { Account: "Volume", Country: "EUR", LEVersion: "LE 12+0 2024" },
          {
            Account: "Volume",
            Country: "Peru Beer",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "South-Africa",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "Dominican Republic Beer",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "Argentina Beer",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "Total ABI",
            LEVersion: "LE 12+0 2024",
          },
        ];

        const tempResults: Result[] = [];

        let sumACLEGACQ224 = 0;
        let sumLYAmendedQ124 = 0;
        let totalABIACLEGACQ224 = 0;
        let totalABILYAmendedQ124 = 0;

        // Process each condition
        conditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const gacValue = matchingRow.ACLEGACQ424;
            const lyValue = matchingRow.LYAMQ4;

            // const numGacValue = parseFloat(gacValue);

            if (!isNaN(gacValue)) {
              const percent = Number(
                ((gacValue / lyValue - 1) * 100).toFixed(1)
              ); // Calculate percentage
              tempResults.push({
                Account: condition.Account,
                Country: condition.Country,
                LEVersion: condition.LEVersion,
                gacValue,
                lyValue,
                percent: percent, // Format percentage
              });

              if (condition.Country === "Total ABI") {
                totalABIACLEGACQ224 = gacValue;
                totalABILYAmendedQ124 = lyValue;
              } else {
                sumACLEGACQ224 += gacValue;
                sumLYAmendedQ124 += lyValue;
              }
            }
          }
        });

        setResults(tempResults);

        // Extract "Country" and "percent" into a new array
        const countryPercentArray = tempResults.map((result) => ({
          country: result.Country.replace("-", " "),
          value: result.percent,
          2023: Number(result.lyValue.toFixed(1)),
          2024: Number(result.gacValue.toFixed(1)),
        }));

        const otherCountries = countryPercentArray.filter(
          (result) =>
            !["Next5", "Tail Market", "Total ABI"].includes(result.country)
        );

        // setCountryPercentArray(countryPercentArray);

        // Set total ABI percentage
        setTotalABI(
          Number(
            ((totalABIACLEGACQ224 / totalABILYAmendedQ124 - 1) * 100).toFixed(1)
          )
        );
        const totalABI = Number(
          Number(
            ((totalABIACLEGACQ224 / totalABILYAmendedQ124 - 1) * 100).toFixed(1)
          )
        );
        const fiveCountriesConditions: any[] = [
          { Account: "Volume", Country: "Canada", LEVersion: "LE 12+0 2024" },
          {
            Account: "Volume",
            Country: "Ecuador Beer",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "South Korea",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "Honduras Beer",
            LEVersion: "LE 12+0 2024",
          },
          {
            Account: "Volume",
            Country: "El Salvador Beer",
            LEVersion: "LE 12+0 2024",
          },
          { Account: "Volume", Country: "Nigeria", LEVersion: "LE 12+0 2024" },
        ];

        let sumFiveCountriesACLEGACQ224 = 0;
        let sumFiveCountriesLYAmendedQ124 = 0;

        fiveCountriesConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const gacValue = parseFloat(matchingRow.ACLEGACQ424);
            const lyValue = matchingRow.LYAMQ4;

            if (!isNaN(gacValue)) {
              sumFiveCountriesACLEGACQ224 += gacValue;
              sumFiveCountriesLYAmendedQ124 += lyValue;
            }
          } else {
          }
        });

        const RestsumACLEGACQ224 = sumFiveCountriesACLEGACQ224 + sumACLEGACQ224;
        const RestsumLYAmendedQ124 =
          sumFiveCountriesLYAmendedQ124 + sumLYAmendedQ124;

        // Tail market Calculation Formula
        if (totalABILYAmendedQ124 - RestsumLYAmendedQ124 !== 0) {
          const calculatedPercent = (
            ((totalABIACLEGACQ224 - RestsumACLEGACQ224) /
              (totalABILYAmendedQ124 - RestsumLYAmendedQ124) -
              1) *
            100
          ).toFixed(1);
          otherCountries.push({
            country: "Tail Market",
            value: Number(calculatedPercent),
            2024: Number((totalABIACLEGACQ224 - RestsumACLEGACQ224).toFixed(1)),
            2023: Number(
              (totalABILYAmendedQ124 - RestsumLYAmendedQ124).toFixed(1)
            ),
          });
          setPercent(calculatedPercent);
        } else {
          setPercent("N/A");
        }

        // Next 5 countries calculation formula
        if (sumFiveCountriesLYAmendedQ124 !== 0) {
          const calculatedFiveCountriesPercent = (
            (sumFiveCountriesACLEGACQ224 / sumFiveCountriesLYAmendedQ124 - 1) *
            100
          ).toFixed(1);
          otherCountries.push({
            country: "Next5",
            value: Number(calculatedFiveCountriesPercent),
            2024: Number(sumFiveCountriesACLEGACQ224.toFixed(1)),
            2023: Number(sumFiveCountriesLYAmendedQ124.toFixed(1)),
          });
          setFiveCountriesPercent(`${calculatedFiveCountriesPercent}%`);
        } else {
          setFiveCountriesPercent("N/A");
        }

        // Add "Total ABI" at the end of the array
        otherCountries.push({
          country: "Total ABI",
          value: Number(totalABI),
          2023: Number(totalABILYAmendedQ124.toFixed(1)),
          2024: Number(totalABIACLEGACQ224.toFixed(1)),
        });
        // Sort the array to ensure the order: Next5, Tail Market, Total ABI
        otherCountries.sort((a, b) => {
          const order = ["Next5", "Tail Market", "Total ABI"];
          return order.indexOf(a.country) - order.indexOf(b.country);
        });

        // Update the state with the sorted array
        setCountryPercentArray(otherCountries);
        console.log("otherCountries", otherCountries);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
      console.log("countryPercentArray", countryPercentArray);
    };

    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="chartTitleData">
          <h2 className="ChartTitle">Volume Performance vs LY</h2>
          <p>
            Volume closed at{" "}
            <span className="yellow">
              {totalABI > 0 ? "+" : ""}
              {totalABI}%
            </span>{" "}
            vs LY with growth observed majorly in South-Africa, Mexico & Next5
            markets; decline coming in majorly from China, Argentina, and Brazil
            Beer.
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="volumeChart">
          <div className="subVolumeChart">
            {countryPercentArray.length > 0 && (
              <BarChart data={countryPercentArray} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCharts;
