import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import VBarChart from "../../../graphs/VBarChart";
import excelFile from "../../../assets/excel/Q4 Sec3 Data.xlsx";

interface ExcelData {
  Country: string;
  Period: string | number; // Period can now be a string or a number (Excel serial date)
  IndustrialProductionIndex: number;
}

interface Props {
  updateEventData: (data: any) => void;
}

// Helper function to convert Excel serial date to JavaScript Date object
const excelDateToJSDate = (serial: number) => {
  const date = new Date((serial - 25569) * 86400 * 1000); // Convert Excel serial date to JS date
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
  return formattedDate; // Return date without time
};

const IPI: React.FC<Props> = ({ updateEventData }) => {
  const [percentageData, setPercentageData] = useState<
    { country: string; value: number }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(excelFile);

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Read the workbook from the array buffer
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the worksheet to JSON
        const jsonData = utils.sheet_to_json<any>(worksheet);

        // Filter out rows without valid data and handle Excel date conversion
        const filteredData = jsonData
          .filter(
            (row: any) =>
              row.Country &&
              row.Values === "Sum of Industrial Production Index_monthly" &&
              (row["2024"] !== undefined || row["2025"] !== undefined)
          )
          .map((row) => ({
            Country: row.Country,
            IndustrialProduction: row["Values"], // Renaming the column to IndustrialProduction
            Production2023: row["2024"] || 0, // Use 0 if the value is missing
            Production2024: row["2025"] || 0, // Use 0 if the value is missing
          }));

        const groupedData: { [key: string]: any[] } = {};
        filteredData.forEach((row: any) => {
          if (row.Country === "Argentina") return; // Exclude Argentina
          if (!groupedData[row.Country]) {
            groupedData[row.Country] = [];
          }
          groupedData[row.Country].push(row);
        });

        const percentageData: { country: string; value: number }[] = [];

        Object.keys(groupedData).forEach((country) => {
          const countryData = groupedData[country][0]; // Assuming only one row per country

          const { Production2023, Production2024 } = countryData;

          // Calculate percentage change
          const percentageChange =
            Production2023 !== 0
              ? ((Production2024 - Production2023) / Production2023) * 100
              : 0;

          percentageData.push({
            country: country,
            value: isNaN(percentageChange)
              ? 0
              : Number(percentageChange.toFixed(1)),
          });
        });

        console.log(percentageData);

        updateEventData({ IPI: percentageData });
        setPercentageData(percentageData);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      {percentageData.length > 0 && (
        <VBarChart data={percentageData} id="IPI_chart" />
      )}
    </div>
  );
};

export default IPI;
